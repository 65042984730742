<template>
    <div class="p-5 bg-white">
        <div id="swaggerUi"></div>
    </div>
</template>

<script>
import 'swagger-ui/dist/swagger-ui.css'
import SwaggerUI from 'swagger-ui'

const config = {
    dom_id: '#swaggerUi',
    url: '/yaml/PublicAPI.yaml',
    plugins: [
        'SwaggerUIBundle.plugins.DownloadUrl',
        'SwaggerUIBundle.plugins.TopBar'
    ],
    deepLinking: true,
    displayRequestDuration: true,
    filter: true,
    operationsSorter: 'alpha',
    showCommonExtensions: true,
    withCredentials: true
}

export default {
    name: 'ApiPage',
    mounted () {
        this._swagger = SwaggerUI(config)
    },
    beforeDestroy () {
        this._swagger = undefined
    }
}
</script>
